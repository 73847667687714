import React from 'react';
import classes from './Banner.module.scss';
import clsx from 'clsx';

import Cross from '../../assets/cross.svg';
import A from '../../atoms/A/A';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import useFrontendEventTracker from 'hooks/useFrontendEventTracker';
import { useRouter } from 'next/router';
export interface Props {
  url: string;
  className?: string;
  closeClassName?: string;
  bannerKey: string;
  children: React.ReactNode;
}

const Banner: React.FC<Props> = ({ url, children, className, bannerKey, closeClassName }) => {
  const [lastClosedBanner, setLastClosedBanner] = useLocalStorageState<string | undefined>(
    'lastClosedBanner',
    undefined,
  );
  const router = useRouter();
  const trackFrontendEvent = useFrontendEventTracker();

  if (lastClosedBanner === bannerKey) {
    return null;
  } else {
    return (
      <aside className={clsx(classes.root, className)}>
        <A
          onClick={() =>
            trackFrontendEvent({
              type: 'banner_clicked',
              value: bannerKey,
            })
          }
          to={url}
        >
          {children}
        </A>
        <Cross
          alt="close"
          className={clsx(classes.close, closeClassName)}
          onClick={() => {
            setLastClosedBanner(bannerKey);
          }}
        />
      </aside>
    );
  }
};
export default Banner;
